var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    { style: _vm.backCss },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.materialData.filter((item) => item.checked).length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "material:delete",
                      expression: "'material:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            { staticClass: "mr-3", on: { click: _vm.downLoad } },
            [_c("a-icon", { attrs: { type: "download" } }), _vm._v(" 下载")],
            1
          ),
          _c("defaultSizeUpload", {
            attrs: {
              btnText: "上传",
              upType: "JPG",
              materialCategoryId: _vm.materialId,
              btnType: "original",
              loadData: _vm.reLoad,
              saveUrl: "/material/edit",
            },
            on: {
              startProgress: _vm.startProgress,
              endProgress: _vm.endProgress,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.reLoad.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.queryParam.fileName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "fileName", $$v)
                          },
                          expression: "queryParam.fileName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.reLoad },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.materialData.length
        ? _c(
            "div",
            { staticClass: "container-water-fall" },
            [
              _c(
                "waterfall",
                {
                  attrs: {
                    col: _vm.col,
                    data: _vm.materialData,
                    "lazy-distance": 150,
                    "load-distance": 150,
                    width: 230,
                    gutterWidth: 30,
                    height: "calc(100vh - 235px)",
                  },
                  on: { loadmore: _vm.loadmore, scroll: _vm.handleScroll },
                },
                [
                  _vm._l(_vm.materialData, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "cell-item",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.imgMouseenter(item)
                          },
                          mouseleave: function ($event) {
                            return _vm.imgMouseleave(item)
                          },
                          click: function ($event) {
                            return _vm.play(item.url)
                          },
                        },
                      },
                      [
                        item.url
                          ? _c("img", {
                              attrs: { "lazy-src": item.url, alt: "加载错误" },
                            })
                          : _vm._e(),
                        item.isMouse ||
                        _vm.materialData.some((item) => item.checked)
                          ? _c(
                              "div",
                              { staticClass: "mouse-check" },
                              [
                                _c("a-checkbox", {
                                  attrs: { checked: item.checked },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                    change: (e) => {
                                      _vm.checkChange(e, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.isMouse
                          ? _c("div", { staticClass: "mouse-option" }, [
                              _c(
                                "div",
                                { staticStyle: { "padding-left": "25px" } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.fileName) +
                                            "\n                "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "mouse-option-title" },
                                        [_vm._v(_vm._s(item.fileName))]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  [
                                    _c("a-icon", {
                                      directives: [
                                        {
                                          name: "has",
                                          rawName: "v-has",
                                          value: "material:delete",
                                          expression: "'material:delete'",
                                        },
                                      ],
                                      attrs: { type: "delete" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.itemDelete(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "download" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.itemDownLoad(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("loading", { attrs: { show: _vm.loading } }),
            ],
            1
          )
        : _vm._e(),
      _c("playvideo-modal", { ref: "videoModal", attrs: { width: "400px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }