import { render, staticRenderFns } from "./materialList.vue?vue&type=template&id=a1ec14d0&scoped=true&"
import script from "./materialList.vue?vue&type=script&lang=js&"
export * from "./materialList.vue?vue&type=script&lang=js&"
import style0 from "./materialList.vue?vue&type=style&index=0&id=a1ec14d0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ec14d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a1ec14d0')) {
      api.createRecord('a1ec14d0', component.options)
    } else {
      api.reload('a1ec14d0', component.options)
    }
    module.hot.accept("./materialList.vue?vue&type=template&id=a1ec14d0&scoped=true&", function () {
      api.rerender('a1ec14d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/memberNotice/materialList.vue"
export default component.exports