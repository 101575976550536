<template>
  <body-layout :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        v-has="'material:delete'"
        v-if="materialData.filter(item => item.checked).length"
        @click="batchDel"
        class="mr-3"
        >删除</a-button
      >
      <a-button @click="downLoad" class="mr-3"><a-icon type="download" /> 下载</a-button>
      <!-- <a-button type="primary" @click="upload">上传</a-button> -->
      <defaultSizeUpload
        btnText="上传"
        upType="JPG"
        :materialCategoryId="materialId"
        @startProgress="startProgress"
        @endProgress="endProgress"
        btnType="original"
        :loadData="reLoad"
        saveUrl="/material/edit"
      />
      <!--  @changeProgress="changeProgress"
        @changeTransProgress="changeTransProgress" -->
      <!--  <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="reLoad">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="queryParam.fileName" placeholder="请输入名称" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="reLoad">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="container-water-fall" v-if='materialData.length'>
      <waterfall
        :col="col"
        :data="materialData"
        @loadmore="loadmore"
        @scroll="handleScroll"
        :lazy-distance="150"
        :load-distance="150"
        :width="230"
        :gutterWidth="30"
        height="calc(100vh - 235px)"
      >
        <template>
          <div
            class="cell-item"
            @mouseenter="imgMouseenter(item)"
            @mouseleave="imgMouseleave(item)"
            @click='play(item.url)'
            v-for="item in materialData"
            :key="item.id"
          >
            <img v-if="item.url" :lazy-src="item.url" alt="加载错误" />
            <div v-if="item.isMouse || materialData.some(item => item.checked)" class="mouse-check">
              <a-checkbox
                :checked="item.checked"
                @click.stop
                @change="
                  e => {
                    checkChange(e, item)
                  }
                "
              >
              </a-checkbox>
            </div>
            <div v-if="item.isMouse" class="mouse-option">
              <div style="padding-left:25px">
                <a-tooltip>
                  <template slot="title">
                    {{ item.fileName }}
                  </template>
                  <div class="mouse-option-title">{{ item.fileName }}</div>
                </a-tooltip>
              </div>
              <div>
                <div>
                  <a-icon type="delete" v-has="'material:delete'" @click.stop="itemDelete(item)" />
                </div>
                <div>
                  <a-icon type="download" @click.stop="itemDownLoad(item)" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </waterfall>
      <loading :show="loading"></loading>
    </div>
    <playvideo-modal ref="videoModal" width='400px' />
  </body-layout>
</template>

<script>
import loading from './modules/loading'
import defaultSizeUpload from '@/components/default-drag-size-upload'
import playvideoModal from '@/components/playvideo-modal'
import { deleteAction, getAction } from '../../api/manage'
import JSZip from 'jszip'
import axios from 'axios'
import FileSaver from 'file-saver'
export default {
  data() {
    return {
      materialId: '',
      queryParam: {
        fileName: ''
      },
      materialData: [],
      col: 6,
      loading: false,
      gitHubData: {},
      originData: [],
      checkList: [],
      current: 1,
      size: 50,
      pages: 0
    }
  },

  components: {
    loading,
    defaultSizeUpload,
    playvideoModal
  },
  created() {
    this.materialId = this.$route.query.id
    if (document.documentElement.clientWidth < 800) {
      this.col = 2
    }
    this.reLoad()
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  methods: {
    play(url) {
      if (this.$refs.videoModal) this.$refs.videoModal.open(url)
    },
    handleScroll(e) {
      this.current = (e.scrollTop / 800).toFixed(0) - 0 + 1
      if (this.current > this.pages) {
        this.current = this.pages
      }
    },
    itemDownLoad(item) {
      let url = item.sourceUrl
      let name = item.fileName || '未命名' + new Date().getTime()
      //第一个参数是图片链接，第二个参数是图片名称
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = url
      eleLink.click()
      eleLink.remove()
    },
    itemDelete(item) {
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: async () => {
          const res = await deleteAction('/material/delete?ids=' + item.id)
          if (res.code == 200) {
            let arr = JSON.parse(JSON.stringify(this.materialData.filter(i=>i.id!=item.id)))
            this.$message.success('删除成功')
            this.materialData=[]
            this.$nextTick(()=>{
              this.materialData=arr
            })
          }
        }
      })
    },
    batchDel() {
      let checkList = this.materialData.filter(item => item.checked)
      if (!checkList.length) {
        this.$message.error('请先勾选数据')
        return
      }
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: async () => {
          const res = await deleteAction('/material/delete?ids=' + checkList.map(item => item.id).join(','))
          if (res.code == 200) {
            let arr = JSON.parse(JSON.stringify(this.materialData.filter(i=>checkList.every(c=>c.id!=i.id))))
            this.$message.success('删除成功')
            this.materialData=[]
            this.$nextTick(()=>{
              this.materialData=arr
            })
          }
        }
      })
    },
    startProgress() {},
    endProgress() {
      this.reLoad()
    },
    async reLoad() {
      this.checkList = []
      this.current = 1
      this.size = 50
      this.loading = true
      this.materialData=[]
      const res = await getAction('/material/page', {
        fileName: this.queryParam.fileName,
        materialCategoryId: this.materialId,
        size: this.size,
        current: this.current
      })
      this.loading = false
      if (res.code == 200) {
        this.materialData = res.data.records.map(item => {
          return {
            ...item,
            isMouse: false,
            checked: false
          }
        })
        this.pages = res.data.pages
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    checkChange(e, item) {
      item.checked = e.target.checked
    },
    imgMouseenter(item) {
      item.isMouse = true
    },
    imgMouseleave(item) {
      item.isMouse = false
    },
    async loadmore() {
      console.log('loadmore')
      this.loading = true
      this.checkList = []
      this.size = 50
      const res = await getAction('/material/page', {
        fileName: this.queryParam.fileName,
        materialCategoryId: this.materialId,
        size: this.size,
        current: this.current
      })
      this.loading = false
      if (res.code == 200) {
        this.materialData = [
          ...this.materialData,
          ...res.data.records
            .filter(item => this.materialData.every(i => i.id != item.id))
            .map(item => {
              return {
                ...item,
                isMouse: false,
                checked: false
              }
            })
        ]
      } else {
        this.$message.error(res.msg)
      }
    },
    downImg(fileList) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      fileList.forEach((item, i) => {
        const promise = this.getFile(item).then(data => {
          // 下载文件, 并存成blob对象
          const file_name = i + item.sourceUrl.substring(item.sourceUrl.lastIndexOf('/') + 1) // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
          FileSaver.saveAs(content, '批量下载.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    getFile(item) {
      let url = item.sourceUrl
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    downLoad() {
      let checkList = this.materialData.filter(item => item.checked)
      if (!checkList.length) {
        this.$message.error('请先勾选图片')
      } else {
        this.downImg(checkList)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container-water-fall {
  box-sizing: border-box;
  h4 {
    padding-top: 56px;
    padding-bottom: 28px;
    font-family: PingFangSC-Medium;
    font-size: 36px;
    color: #000000;
    letter-spacing: 1px;
    text-align: justify;
  }
  button {
    // background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    background-color: yellow;
    color: #24292e;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 0.25em;
    width: 100px;
    line-height: 26px;
    font-size: 13px;

    margin: 4px 0;
    margin-right: 4px;
    cursor: pointer;
    outline: none;
    &.blue-light {
      background: #27fbc2;
    }
  }
  button:hover {
    background-image: linear-gradient(-180deg, #fafbfc, #ccc 90%);
  }
  .cell-item {
    margin-top: 30px;
    width: 100%;
    // margin-bottom: 18px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    border: 2px solid rgb(230, 226, 226);
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    img {
      // border-radius: 12px 12px 0 0;
      width: 100%;
      height: auto !important;
      display: block;
    }
    .mouse-check {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;
    }
    .mouse-option {
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #fff;
      padding: 10px;
      > div {
        display: flex;
        justify-content: space-between;
        .anticon {
          font-size: 18px;
          &:hover {
            color: var(--theme-color);
          }
        }
      }
      .mouse-option-title {
        width: 205px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.vue-waterfall::-webkit-scrollbar-button {
  height: 0;
}
.vue-waterfall::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
.vue-waterfall::-webkit-scrollbar-track {
  border-radius: 0;
}
.vue-waterfall::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.vue-waterfall::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.githubdata {
  float: right;
  margin-right: 90px;
  img {
    width: 14px;
    // height: 16px;
  }
}
.action {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
}
.container-water-fall .vue-waterfall .vue-waterfall-column .cell-item:nth-child(1) {
  margin-top: 0 !important;
}
</style>
